import React, { useEffect, useMemo, useState } from "react";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { DebounceInput } from "react-debounce-input";
import { useGetSettings, useUpdateSetting } from "../api/Settings.hooks";
import { useAuth, ACTIONS } from "../providers/authProvider";
import { sharedHelper } from "../helpers/sharedHelper";
import Icon from "../components/Icon";
import TooltipItem from "../components/TooltipItem";
import Select from "../components/Select";
import AdvanceTable from "../components/advanceTable/AdvanceTable";
import ConfirmationModal from "../components/ConfirmationModal";
import DynamicAttributesModal from "../components/dynamicAttributes/DynamicAttributesModal";
import CustomCheckbox from "../components/CustomCheckbox";
import CurrencyInput from "react-currency-input-field";

const isTest = typeof jest !== "undefined";

const columns = (onChangeSetting, onSeeAttributes, workOrderStatus = []) => [
  {
    accessor: "name",
    header: "Setting",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const value = rowData.row.name;
      return (
        <div data-value={value} className="d-flex align-items-center">
          {value}
          {rowData.row.description ? (
            <div className="ms-2">
              <TooltipItem
                id={`${rowData.row.id}-tooltip`}
                title={rowData.row.description}
              >
                <Icon
                  name="info"
                  className="text-primary"
                  style={{ paddingBottom: "1px" }}
                />
              </TooltipItem>
            </div>
          ) : null}
        </div>
      );
    },
  },
  {
    accessor: "status",
    header: "Status",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) =>
      !rowData.row.valueOnly ? (
        <CustomCheckbox
          checked={rowData.row.status}
          data-value={rowData.row.status ? "ON" : "OFF"}
          onClick={() =>
            onChangeSetting(rowData.row, "status", !rowData.row.status)
          }
          data-testid="status-switch"
          id={rowData.row.id}
          name={rowData.row.id}
          className="d-inline-flex align-items-center"
          small
        />
      ) : null,
  },
  {
    accessor: "value",
    header: "Value",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { value, status } = rowData.row;
      if (!rowData.row.statusOnly) {
        let defaultValue;
        switch (rowData.row.valueType) {
          case "WORK_ORDER_STATUS":
            const workOrderStatusOptions = workOrderStatus.map((status) => ({
              value: status.id,
              label: status.name,
            }));
            defaultValue = workOrderStatusOptions.find(
              (status) => status.value === value
            );
            return (
              <Select
                id="statusSelect"
                name="statusSelect"
                data-testid="status-select"
                className="bg-transparent flex-grow-1"
                placeholder="Select the status"
                data-value={defaultValue ? defaultValue.label : "-"}
                value={defaultValue || ""}
                options={workOrderStatusOptions}
                onChange={(selected) =>
                  onChangeSetting(rowData.row, "value", selected.value)
                }
              />
            );
          case "COMBO_BOX":
            defaultValue = rowData.row.valueOptions.find(
              (option) => option.value === value
            );
            return (
              <Select
                id="comboBoxSelect"
                name="comboBoxSelect"
                data-testid="combo-box-select"
                className="bg-transparent flex-grow-1"
                placeholder="Select something"
                data-value={defaultValue ? defaultValue.label : "-"}
                value={defaultValue || ""}
                options={rowData.row.valueOptions}
                onChange={(selected) =>
                  onChangeSetting(rowData.row, "value", selected.value)
                }
              />
            );
          case "DYNAMIC_ATTRIBUTES":
            return (
              <Button
                size="sm"
                color="primary"
                data-value={"-"}
                onClick={() =>
                  onSeeAttributes(rowData.row.name, rowData.row.value)
                }
              >
                See attributes
              </Button>
            );
          case "CURRENCY":
            return (
              <CurrencyInput
                data-testid={`setting-value-${rowData.row.id}`}
                className="form-control-redesign"
                id={`setting-value-${rowData.row.id}`}
                name={`setting-value-${rowData.row.id}`}
                prefix="$"
                decimalsLimit={2}
                max={9999}
                allowNegativeValue={false}
                placeholder="Enter value"
                data-value={rowData.row.value || ""}
                value={rowData.row.value || ""}
                onValueChange={(value) => {
                  onChangeSetting(rowData.row, "value", value);
                }}
              />
            );
          default:
            return (
              <DebounceInput
                debounceTimeout={900}
                disabled={rowData.row.valueReadOnly}
                placeholder={`${rowData.row.valuePlaceholder}`}
                className="form-control"
                data-testid={`setting-value-${rowData.row.id}`}
                maxLength="50"
                min={0}
                type={rowData.row.valueType.toLowerCase()}
                data-value={value || ""}
                value={value}
                onChange={(e) =>
                  onChangeSetting(rowData.row, "value", e.target.value)
                }
              />
            );
        }
      }
      return status ? value || "" : "";
    },
  },
];

const Settings = ({ pkg, HeaderActions }) => {
  const [authContext, setAuthContext] = useAuth();

  const [settings, setSettings] = useState([]);
  const [refresh, setRefresh] = useState();
  const [dynamicAttributesModal, setDynamicAttributesModal] = useState();
  const [confirmationModal, setConfirmationModal] = useState();

  const settingsPackage = useMemo(
    () =>
      pkg && authContext.userData
        ? authContext.userData.packages.find((p) => p.path === pkg)
        : null,
    [authContext.userData, pkg]
  );

  const {
    data: settingsData,
    isLoading: isLoadingSettings,
    get: getSettings,
  } = useGetSettings();

  useEffect(() => {
    getSettings({
      packageId: settingsPackage.id,
    });
  }, [getSettings, settingsPackage, refresh]);

  useEffect(() => {
    if (settingsData) {
      setSettings(settingsData);
    }
  }, [settingsData, setSettings]);

  const {
    data: updateSettingData,
    isLoading: isLoadingUpdateSetting,
    update: updateSetting,
  } = useUpdateSetting();

  useEffect(() => {
    if (updateSettingData) {
      if (!isTest) {
        setAuthContext({
          action: ACTIONS.REFRESH,
        });
      }
      sharedHelper.successToast(`Setting saved`);
    }
  }, [updateSettingData, setAuthContext]);

  const onSeeAttributes = (setting, dynamicAttributes) =>
    setDynamicAttributesModal({ setting, dynamicAttributes });

  const onChangeSetting = (setting, attribute, value) => {
    const upSettings = [...settings];
    const index = upSettings.findIndex((s) => s.id === setting.id);
    upSettings.splice(index, 1, {
      ...setting,
      [attribute]: value,
      touched: true,
    });
    sharedHelper.warningToast("Remember to save changes");
    setSettings(upSettings);
  };

  const onSaveSettings = () => {
    const touchedSettings = settings.filter((s) => s.touched);
    if (!touchedSettings.length) {
      setConfirmationModal();
      return sharedHelper.warningToast("No changes applied");
    }
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        for (const setting of touchedSettings) {
          await updateSetting({
            ...setting,
          });
        }
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Save setting",
      body: `Are you sure you want to save this setting changes?`,
      confirmText: "Confirm",
    });
  };

  const nonInternalSettings = useMemo(
    () => settings.filter((setting) => !setting.isInternal),
    [settings]
  );

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">
              {`${settingsPackage?.name || "Core"} `}Settings
            </h2>
            <small className="text-muted ms-2 pt-1">
              ({nonInternalSettings.length})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            {HeaderActions && <HeaderActions settings={settings} />}
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body overflow-visible">
          <AdvanceTable
            columns={columns(
              onChangeSetting,
              onSeeAttributes,
              authContext.userData.workOrderStatus
            )}
            data={nonInternalSettings}
            pageSize={nonInternalSettings.length || 1}
            isLoading={isLoadingSettings || isLoadingUpdateSetting}
            headerClassName="text-muted small"
            tableProps={{
              striped: true,
            }}
          />
          <div className="d-flex align-items-center justify-content-between">
            <Button
              className="ms-2"
              color="secondary"
              onClick={() => setRefresh((prev) => !prev)}
            >
              Discard
            </Button>
            <Button className="ms-2" color="primary" onClick={onSaveSettings}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : dynamicAttributesModal ? (
        <DynamicAttributesModal
          dynamicAttributes={dynamicAttributesModal.dynamicAttributes}
          setting={dynamicAttributesModal.setting}
          onSubmit={(settingName, value) => {
            const setting = nonInternalSettings.find(
              (s) => s.name === settingName
            );
            onChangeSetting(setting, "value", JSON.stringify(value));
            setDynamicAttributesModal();
          }}
          onClose={() => setDynamicAttributesModal()}
        />
      ) : null}
    </Container>
  );
};

export default Settings;
