import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Row, FormGroup, Label } from "reactstrap";

import { useGetCustomers } from "@crewos/customers";

import { components, data, sharedHelper } from "@crewos/shared";

const { useAuth } = data;

const { Select, DatesSelector, DynamicAttributeInput, DynamicAttributeLabel } =
  components;

const PAGE_SIZE_CUSTOMERS = 30;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const WorkOrderForm = ({ workOrderData, setWorkOrderData }) => {
  const [authContext] = useAuth();
  const [customerSearch, setCustomerSearch] = useState();
  const [lastSelectedCustomer, setLastSelectedCustomer] = useState(null);

  const serviceLocationEnabled = useMemo(
    () =>
      authContext.userData
        ? sharedHelper.isSettingEnabled(
            authContext.userData.packages,
            "core",
            "SERVICE_LOCATIONS_ENABLED"
          )
        : false,
    [authContext.userData]
  );

  const {
    data: customers,
    isLoading: isLoadingCustomers,
    get: getCustomers,
  } = useGetCustomers();

  const {
    data: defaultCustomer,
    isLoading: isLoadingDefaultCustomer,
    get: getDefaultCustomer,
  } = useGetCustomers();

  const customersData = useMemo(() => {
    return (defaultCustomer?.data || []).concat(customers?.data || []);
  }, [customers, defaultCustomer]);

  useEffect(() => {
    getDefaultCustomer({
      isDefault: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCustomers({
      search: customerSearch,
      pageSize: PAGE_SIZE_CUSTOMERS,
      isDefault: "false",
    });
  }, [getCustomers, customerSearch]);

  const handleChangeData = useCallback(
    (fieldName, isSelect = false) => {
      return (event) => {
        const data = {
          ...workOrderData,
          [fieldName]: isSelect ? event?.value : event?.target?.value,
        };
        if (fieldName === "customerId") {
          data.customerLocationId = null;
          const customer = customersData?.find(
            (customer) => customer.id === event?.value
          );
          setLastSelectedCustomer(customer);
        }
        setWorkOrderData(data);
      };
    },
    [workOrderData, setWorkOrderData, customersData]
  );

  const customerSelected = useMemo(() => {
    const customerDataList = lastSelectedCustomer
      ? [...customersData, lastSelectedCustomer]
      : customersData;
    return workOrderData?.customerId
      ? customerDataList?.find(
          (customer) => customer.id === workOrderData?.customerId
        )
      : customerDataList?.find((customer) => customer.isDefault);
  }, [customersData, workOrderData, lastSelectedCustomer]);

  const customerSelectedOption = useMemo(() => {
    return {
      label: customerSelected?.customerName,
      value: customerSelected?.id,
    };
  }, [customerSelected]);

  const customerLocationSelected = useMemo(() => {
    const locations = customerSelected?.locations;
    if (locations) {
      return workOrderData?.customerLocationId
        ? locations.find(
            (location) => location.id === workOrderData?.customerLocationId
          )
        : locations.find((location) => location.isDefault);
    }
    return null;
  }, [customerSelected, workOrderData?.customerLocationId]);

  const customerLocationSelectedOption = useMemo(() => {
    if (customerLocationSelected) {
      return {
        label: sharedHelper.getAddress(customerLocationSelected),
        value: customerLocationSelected?.id,
      };
    }
    return null;
  }, [customerLocationSelected]);

  const customersOptions = useMemo(() => {
    return customersData.map((option) => ({
      label: option.customerName,
      value: option.id,
    }));
  }, [customersData]);

  const customerLocationOptions = useMemo(() => {
    return customerSelected?.locations.map((option) => ({
      label: sharedHelper.getAddress(option),
      value: option.id,
    }));
  }, [customerSelected]);

  const isSuperAdmin = useMemo(
    () =>
      sharedHelper.userHasScope(authContext.userData?.role, [
        IS_SUPER_ADMIN_USER,
      ]),
    [authContext.userData]
  );

  const statusSelect = useMemo(() => {
    return authContext.userData.workOrderStatus?.map((status) => ({
      value: status.id,
      label: status.name,
    }));
  }, [authContext.userData.workOrderStatus]);

  const selectedStatusOption = useMemo(() => {
    const status = authContext.userData.workOrderStatus?.find(
      (s) => s.id === workOrderData?.workOrderStatusId
    );
    if (status) {
      return {
        value: status?.id,
        label: status?.name,
      };
    }
    return "";
  }, [workOrderData?.workOrderStatusId, authContext.userData.workOrderStatus]);

  const internalSelect = useMemo(() => {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  }, []);

  const defaultInternal = useMemo(() => {
    return internalSelect.find(
      (option) => option.value === workOrderData?.isInternal
    );
  }, [workOrderData?.isInternal, internalSelect]);

  const dynamicAttributes = useMemo(
    () =>
      sharedHelper.getSettingValue(
        authContext.userData?.packages,
        "workorders",
        "WO_DYNAMIC_ATTRIBUTES"
      ) || [],
    [authContext.userData]
  );

  const serviceLocationsOptions = useMemo(() => {
    if (authContext.userData) {
      return authContext.userData.serviceLocations
        .filter((sl) => sl.id !== workOrderData?.serviceLocationId)
        .map((sl) => ({ value: sl.id, label: sl.name }));
    }
  }, [authContext.userData, workOrderData?.serviceLocationId]);

  const selectedServiceLocation = useMemo(() => {
    if (workOrderData?.serviceLocationId && authContext.userData) {
      const selectedServiceLocation =
        authContext.userData.serviceLocations.find(
          (sl) => sl.id === workOrderData?.serviceLocationId
        );
      return selectedServiceLocation
        ? {
            value: selectedServiceLocation.id,
            label: selectedServiceLocation.name,
          }
        : null;
    }
  }, [authContext.userData, workOrderData?.serviceLocationId]);

  return (
    <Row className="px-0">
      <FormGroup className="col-6 px-3">
        <Label>
          <span>Work Order #</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        <input
          maxLength="100"
          type="text"
          className="form-control-redesign"
          placeholder="Enter a work order #"
          name="workOrderNumber"
          value={workOrderData.workOrderNumber || ""}
          onChange={handleChangeData("workOrderNumber")}
          required
        />
      </FormGroup>
      <FormGroup className="col-6 px-3">
        <Label>
          <span>Status</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        <Select
          placeholder="Select the status"
          options={statusSelect}
          value={selectedStatusOption}
          name="status"
          onChange={handleChangeData("workOrderStatusId", true)}
        />
      </FormGroup>
      {!workOrderData.isInternal && (
        <>
          <FormGroup className="col-6 px-3">
            <Label>
              <span>Customer</span>
            </Label>
            <Select
              placeholder="Search customers"
              data-testid="customer-select"
              noOptionsMessage={() => "No customers found"}
              options={customersOptions}
              isSearchable
              value={customerSelectedOption}
              onInputChange={setCustomerSearch}
              isLoading={isLoadingCustomers || isLoadingDefaultCustomer}
              name="customer"
              onChange={handleChangeData("customerId", true)}
            />
          </FormGroup>
          <FormGroup className="col-6 px-3">
            <Label>
              <span>Customer Location</span>
            </Label>
            <Select
              placeholder="Search customer locations"
              data-testid="customer-location-select"
              noOptionsMessage={() => "No locations found"}
              options={customerLocationOptions}
              value={customerLocationSelectedOption}
              isLoading={isLoadingCustomers}
              name="customerLocation"
              onChange={handleChangeData("customerLocationId", true)}
            />
          </FormGroup>
        </>
      )}
      <FormGroup className="col-6 px-3" noMargin={true}>
        <DatesSelector
          name="dates"
          defaultStartDate={workOrderData.startDate}
          defaultEndDate={workOrderData.endDate}
          onSubmit={(startDate, endDate) => {
            setWorkOrderData({
              ...workOrderData,
              startDate,
              endDate,
            });
          }}
        />
      </FormGroup>
      {isSuperAdmin ? (
        <FormGroup className="col-6 px-3">
          <Label>
            <span>Is Internal</span>
          </Label>
          <Select
            id="internalSelect"
            name="internalSelect"
            onChange={handleChangeData("isInternal", true)}
            value={defaultInternal}
            options={internalSelect}
            required
          />
        </FormGroup>
      ) : null}
      {serviceLocationEnabled ? (
        <FormGroup className="col-6 px-3">
          <Label>
            <span>Service Location</span>
            <span className="text-danger ms-2">*</span>
          </Label>
          <Select
            name="slSelect"
            onChange={(selected) =>
              setWorkOrderData({
                ...workOrderData,
                serviceLocationId: selected?.value,
              })
            }
            value={selectedServiceLocation}
            placeholder="Select a service location"
            options={serviceLocationsOptions}
            data-testid="service-locations-select"
            required
          />
        </FormGroup>
      ) : null}
      {dynamicAttributes.map((dynamicAttribute) => (
        <FormGroup className="col-6 px-3" key={dynamicAttribute.id}>
          <Label>
            <DynamicAttributeLabel dynamicAttribute={dynamicAttribute} />
          </Label>
          <DynamicAttributeInput
            dynamicAttribute={dynamicAttribute}
            data={workOrderData}
            setData={setWorkOrderData}
          />
        </FormGroup>
      ))}
    </Row>
  );
};

export default WorkOrderForm;
