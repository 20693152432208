import React from "react";
import { sharedHelper } from "./sharedHelper";
import Icon from "../components/Icon";

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";
const IS_ADMIN_USER = "IS_ADMIN_USER";

const filterRoutes = (routes, userData) =>
  routes
    .filter((route) => {
      const isSettingEnabled = route.setting
        ? sharedHelper.isSettingEnabled(
            userData.packages,
            route.package,
            route.setting
          )
        : true;
      const routeMeetsRole = sharedHelper.routeMeetsRole(
        route,
        userData.role.userRoleScopes
      );
      return route.sidebar && isSettingEnabled && routeMeetsRole;
    })
    .sort((a, b) => (a.order || 0) - (b.order || 0));

const filterEntries = (entries, userData, pkg) =>
  entries
    .filter((entry) => {
      const isSettingEnabled = entry.setting
        ? sharedHelper.isSettingEnabled(
            userData.packages,
            entry.package || pkg,
            entry.setting
          )
        : true;
      const entryMeetsRole = sharedHelper.routeMeetsRole(
        entry,
        userData.role.userRoleScopes
      );
      return entry.sidebar && isSettingEnabled && entryMeetsRole;
    })
    .sort((a, b) => (a.order || 0) - (b.order || 0));

export const getRoutes = (routes, userData) => {
  const flatRoutes = Object.values(routes).flatMap(
    (routeGroup) => routeGroup.routes
  );
  const filteredRoutes = filterRoutes(flatRoutes, userData);
  const settingRoutes = {
    name: "Settings",
    icon: "settings",
    path: "/settings",
    isSettings: true,
    entries: filteredRoutes.filter((route) => route.isSetting),
  };
  const isAdmin = sharedHelper.userHasScope(userData?.role, [
    IS_SUPER_ADMIN_USER,
    IS_ADMIN_USER,
  ]);
  const result = [...filteredRoutes.filter((route) => !route.isSetting)];
  if (isAdmin) {
    result.push(settingRoutes);
  }
  return result;
};

export const getEntriesByCategory = (
  name,
  isOpen,
  userData,
  pkg,
  entries = []
) => {
  if (!userData) {
    return {};
  }
  const sidebarEntries = filterEntries(entries, userData, pkg);
  return sidebarEntries.reduce((p, c) => {
    if (!c.category || c.category === "N/A") {
      c.category = !isOpen ? name : "N/A";
    }
    if (!p[c.category]) {
      p[c.category] = [];
    }
    p[c.category].push(c);
    return p;
  }, {});
};

export const getEntryNotifications = (entry, userData) => {
  const entryNotification =
    userData &&
    Object.keys(userData.notifications).find((path) => path === entry.path);
  const suffix =
    entryNotification && userData.notifications[entryNotification] ? (
      <Icon name="alert-circle" className="text-warning align-middle mx-2" />
    ) : (
      false
    );
  return suffix;
};

export const menuItemStyles = {
  root: {
    color: "#757575",
  },
  SubMenuExpandIcon: ({ collapsed }) => ({
    display: collapsed ? "none" : "block",
    color: "inherit",
  }),
  subMenuContent: {
    backgroundColor: process.env.REACT_APP_BASE_COLOR_LIGHT,
    borderRadius: 8,
  },
  suffix: {
    display: "flex",
    alignItems: "center",
  },
  button: ({ level }) => {
    const isParent = level === 0;
    const base = {
      justifyContent: "center",
      borderRadius: 8,
      padding: ".625rem .75rem",
      "&.ps-disabled": {
        color: "var(--bs-secondary-color)",
        cursor: "default",
        fontWeight: 500,
        height: 22,
      },
    };
    if (isParent) {
      return {
        ...base,
        height: 37.5,
        "&.ps-active": {
          backgroundColor: process.env.REACT_APP_BASE_COLOR_LIGHT,
          color: process.env.REACT_APP_BASE_COLOR,
        },
        "&.ps-open": {
          backgroundColor: process.env.REACT_APP_BASE_COLOR_LIGHT,
          color: process.env.REACT_APP_BASE_COLOR,
        },
        "&:not(.ps-active):hover": {
          backgroundColor: process.env.REACT_APP_BASE_COLOR,
          color: "white",
        },
      };
    }
    return {
      ...base,
      height: 32,
      padding: ".5rem 1.5rem",
      "&.ps-active": {
        backgroundColor: process.env.REACT_APP_BASE_COLOR,
        color: "white",
      },
      "&:not(.ps-active):hover": {
        backgroundColor: process.env.REACT_APP_BASE_COLOR_LIGHT,
        color: process.env.REACT_APP_BASE_COLOR,
      },
    };
  },
  icon: ({ level }) => {
    const isParent = level === 0;
    const base = {
      width: "fit-content",
      minWidth: "fit-content",
      marginRight: ".5rem",
    };
    if (isParent) {
      return {
        ...base,
        "&.ps-active": {
          backgroundColor: process.env.REACT_APP_BASE_COLOR_LIGHT,
          color: process.env.REACT_APP_BASE_COLOR,
        },
        "&:not(.ps-active):hover": {
          backgroundColor: process.env.REACT_APP_BASE_COLOR,
          color: "white",
        },
      };
    }
    return {
      ...base,
      "&.ps-active": {
        backgroundColor: process.env.REACT_APP_BASE_COLOR,
        color: "white",
      },
      "&:not(.ps-active):hover": {
        backgroundColor: process.env.REACT_APP_BASE_COLOR_LIGHT,
        color: process.env.REACT_APP_BASE_COLOR,
      },
    };
  },
  label: ({ level, hover }) => {
    const isParent = level === 0;
    if (isParent) {
      return {
        fontSize: 15,
        color: "inherit",
      };
    }
    return {
      fontSize: 13,
      color: hover ? "white" : "",
    };
  },
};
