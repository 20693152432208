import React, { useState, useCallback, useMemo } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import { useCrewWorkDays } from "@crewos/crews";
import { components, data } from "@crewos/shared";
import AssignTemplateWOModal from "../workOrderAssignedTemplates/AssignTemplateWOModal";
import AllSafety from "./AllSafety";

const { WorkOrderDateSelector, Select } = components;
const { useWorkOrderDetails } = data;

const WorkOrderSafetyList = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext] = useCrewWorkDays();
  const [search, setSearch] = useState();
  const [viewPerDay, setViewPerDay] = useState(true);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(0);

  const handleSearch = useCallback(($event) => {
    setSearch($event.target.value);
  }, []);

  const handleViewPerDay = useCallback(() => {
    setViewPerDay(!viewPerDay);
  }, [viewPerDay]);

  const handleAssignTemplate = useCallback(() => {
    setShowAssignModal(false);
    setNeedsUpdate((prev) => prev + 1);
  }, []);

  const crewWorkDays = useMemo(() => {
    return (
      crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate] || []
    );
  }, [crewWorkDaysContext, workOrderDetails]);

  const crewWorkDaysWithSafety = useMemo(() => {
    return crewWorkDays.filter((crewWorkDay) => crewWorkDay.safetyForms > 0);
  }, [crewWorkDays]);

  const viewPerDaySelect = useMemo(() => {
    return [
      { label: "View per day", value: true },
      { label: "View all work days", value: false },
    ];
  }, []);

  const defaultViewPerDay = useMemo(() => {
    return viewPerDaySelect.find((option) => option.value === viewPerDay);
  }, [viewPerDay, viewPerDaySelect]);

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 px-0 box-shadow-none mb-3">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h2 className="mb-0 col-6">Safety</h2>
              <div className="col-6 row">
                <div className="col-4">
                  <Select
                    id="view-per-day"
                    name="view-per-day"
                    value={defaultViewPerDay}
                    onChange={handleViewPerDay}
                    options={viewPerDaySelect}
                  />
                </div>
                <div className="col-8">
                  <DebounceInput
                    className="search border-0 form-control"
                    maxLength={50}
                    minLength={1}
                    debounceTimeout={900}
                    placeholder="Search something"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            {viewPerDay && (
              <WorkOrderDateSelector
                crewWorkDays={crewWorkDaysContext.crewWorkDays}
              />
            )}
          </CardBody>
        </Card>
        {!viewPerDay ? (
          <AllSafety
            workOrder={workOrderDetails.workOrder}
            isViewPerDay={viewPerDay}
            needsUpdate={needsUpdate}
            search={search}
            crewWorkDays={[]}
          />
        ) : !crewWorkDays.length || !crewWorkDaysWithSafety.length ? (
          <Card className="w-100 px-0 box-shadow-none mb-3">
            <CardBody className="py-3">
              <div className="text-center text-muted small">
                No data to display
              </div>
            </CardBody>
          </Card>
        ) : (
          <AllSafety
            workOrder={workOrderDetails.workOrder}
            crewWorkDays={crewWorkDays}
            isViewPerDay={viewPerDay}
            needsUpdate={needsUpdate}
            search={search}
          />
        )}
      </Row>
      {showAssignModal && (
        <AssignTemplateWOModal
          isOpen={showAssignModal}
          workOrder={workOrderDetails.workOrder}
          onAssign={handleAssignTemplate}
          onClose={() => setShowAssignModal(false)}
        />
      )}
    </Col>
  );
};

export default WorkOrderSafetyList;
