import React, { useState, useEffect, useMemo } from "react";

import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import { useUpdateLocationAssets } from "../api/Customers.hooks";
import NewCustomerLocationAssetsModal from "./NewCustomerLocationAssetModal";

const { Loader, Icon } = components;
const { useAuth } = data;

const CustomerLocationAssetsModal = ({
  customerLocation,
  onClose,
  onSubmit,
}) => {
  const [authContext] = useAuth();
  const [newAssetModal, setNewAssetModal] = useState();
  const [assets, setAssets] = useState(customerLocation.assets);

  const {
    isLoading: isLoadingUpdateAssets,
    update: updateLocationAssets,
    data: updateLocationAssetsData,
  } = useUpdateLocationAssets();

  const doSubmit = async () => {
    updateLocationAssets({
      id: customerLocation.id,
      assets,
    });
  };

  useEffect(() => {
    if (updateLocationAssetsData) {
      onSubmit();
    }
  }, [updateLocationAssetsData, onSubmit]);

  const onDelete = (index) => {
    let newAssets = [...assets];
    newAssets.splice(index, 1);
    setAssets(newAssets);
  };

  const dynamicAttributes = useMemo(
    () =>
      sharedHelper.getSettingValue(
        authContext.userData?.packages,
        "customers",
        "ASSET_DYNAMIC_ATTRIBUTES"
      ) || [],
    [authContext.userData]
  );

  return newAssetModal ? (
    <NewCustomerLocationAssetsModal
      onSubmit={(newAsset) => {
        setAssets([
          ...assets,
          { customerLocationId: customerLocation.id, ...newAsset },
        ]);
        setNewAssetModal();
      }}
      onClose={() => setNewAssetModal()}
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="xl">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Assets for Location
      </ModalHeader>
      <ModalBody className="text-center">
        <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
          <thead>
            <tr className="bg-graylight small text-muted">
              <th>Name</th>
              <th>Model #</th>
              <th>Serial #</th>
              <th>Manufacturer</th>
              {dynamicAttributes.map((dynamicAttribute) => (
                <th>{dynamicAttribute.label}</th>
              ))}
              <th>Templates</th>
              {assets.length ? <th className="min-width-50"></th> : null}
            </tr>
          </thead>
          <tbody>
            {assets.map((asset, index) => (
              <tr key={index}>
                <td title={asset.name} className="max-width-200">
                  <span className="text-truncate-2">{asset.name || "-"}</span>
                </td>
                <td title={asset.modelNumber} className="max-width-200">
                  <span className="text-truncate-2">
                    {asset.modelNumber || "-"}
                  </span>
                </td>
                <td title={asset.serialNumber} className="max-width-200">
                  <span className="text-truncate-2">
                    {asset.serialNumber || "-"}
                  </span>
                </td>
                <td title={asset.manufacturer} className="max-width-200">
                  <span className="text-truncate-2">
                    {asset.manufacturer || "-"}
                  </span>
                </td>
                {dynamicAttributes.map((dynamicAttribute) => (
                  <td title={asset.dynamicAttributes[dynamicAttribute.id]}>
                    <span className="text-truncate-2">
                      {asset.dynamicAttributes[dynamicAttribute.id] || "-"}
                    </span>
                  </td>
                ))}
                <td title={asset.inspectionTemplateWorkOrders?.length}>
                  {asset.inspectionTemplateWorkOrders?.length || 0}
                </td>
                <td>
                  <div
                    onClick={() => onDelete(index)}
                    className="cursor-pointer"
                  >
                    <Icon name="trash" className="text-danger" />
                  </div>
                </td>
              </tr>
            ))}
            {!assets.length ? (
              <tr>
                <td
                  colSpan={dynamicAttributes.length + 1}
                  className="text-muted small"
                >
                  No assets
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <div className="d-flex align-items-center">
          <Button color="white" onClick={onClose}>
            Discard
          </Button>
          <Button
            className="ms-2"
            color="secondary"
            onClick={() => setNewAssetModal(true)}
          >
            Create New
          </Button>
        </div>
        {isLoadingUpdateAssets ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button color="primary" onClick={doSubmit}>
            Confirm
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CustomerLocationAssetsModal;
