import React, { useMemo, useState, useCallback, useEffect } from "react";

import { components, sharedHelper, data } from "@crewos/shared";

import moment from "moment";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";

import {
  calculateHoursWithExceptions,
  EditWorkTimeModal,
  useGetWorkTimes,
} from "@crewos/worktimes";

const dateFormat = "MM/DD";

const WORK_TIMES_PACKAGE = "worktimes";

const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";

const { AdvanceTable, Loader, TooltipItem, Icon } = components;

const { useAuth, WORK_ORDER_DETAILS_TABS } = data;

const LaborCostDetail = ({
  startDate,
  endDate,
  workOrderId,
  workOrderNumber,
  employeeId,
  workTimeTypeId,
  workTimeTypeName,
  firstName,
  lastName,
  onClose,
}) => {
  const [authContext] = useAuth();

  const {
    data: workTimesData,
    isLoading,
    get: getWorkTimes,
  } = useGetWorkTimes();

  const [touched, setTouched] = useState();
  const [editWorkTimeModal, setEditWorkTimeModal] = useState();
  const [refresh, setRefresh] = useState();
  const [workTimes, setWorkTimes] = useState([]);

  useEffect(() => {
    const params = {
      startDate,
      endDate,
      workOrderId,
      workTimeTypeId,
    };
    if (employeeId) {
      params.employeeId = employeeId;
    }
    getWorkTimes(params);
  }, [
    getWorkTimes,
    refresh,
    employeeId,
    workOrderId,
    workTimeTypeId,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (workTimesData) {
      setWorkTimes(workTimesData);
    }
  }, [workTimesData, setWorkTimes]);

  const onSubmitTimes = useCallback(() => {
    setRefresh((refresh) => !refresh);
    setTouched(true);
  }, []);

  const handleClose = useCallback(() => onClose(touched), [onClose, touched]);

  const onEditEntry = (entry) => {
    const workTime = workTimes.find((workTime) => workTime.id === entry.id);
    workTime.employee.employeeCrew = workTime.employeeCrew;
    setEditWorkTimeModal(workTime);
  };

  const totalHours = useMemo(
    () =>
      workTimes
        .reduce((p, c) => p + calculateHoursWithExceptions(c), 0)
        .toFixed(2),
    [workTimes]
  );

  const startEndTimeSettingEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        WORK_TIMES_PACKAGE,
        START_END_WORK_TIME_SETTING
      ),
    [authContext.userData]
  );

  const totalColumns = [
    {
      accessor: "Work Order",
      header: "Work Order",
      disableFilter: true,
      headerProps: { className: "text-start" },
      cellProps: { className: "text-start text-truncate" },
      Cell: () => {
        return (
          <a
            className="text-link"
            target="_blank"
            rel="noreferrer"
            data-value={workOrderNumber}
            data-testid="work-order-link"
            href={`/workorders/details/${workOrderId}/${WORK_ORDER_DETAILS_TABS.JOB_DETAILS_TAB}`}
          >
            {workOrderNumber}
          </a>
        );
      },
    },
    {
      accessor: "workTimeType",
      header: "Type",
      disableFilter: true,
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      Cell: () => {
        return workTimeTypeName;
      },
    },
    {
      accessor: "Total Hours",
      header: "Total Hours",
      disableFilter: true,
      headerProps: { className: "text-end" },
      cellProps: { className: "text-end" },
      type: "number",
      Cell: () => {
        return totalHours;
      },
    },
  ];

  const detailColumns = [
    {
      accessor: "crewWorkDay",
      header: "Date",
      headerProps: { className: "text-start" },
      cellProps: { className: "text-start" },
      Cell: (rowData) => {
        const date = moment(rowData.row.crewWorkDay.date);
        return `${date.format("dd")} ${date.format(dateFormat)}`;
      },
    },
  ];

  if (startEndTimeSettingEnabled) {
    detailColumns.push({
      accessor: "startTime",
      header: "Start Time",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      type: "string",
      Cell: (rowData) => {
        const startTime = rowData.row.startTime;
        const value = moment(startTime).isValid()
          ? sharedHelper.formatDateTime(startTime, "h:mm a")
          : "-";
        return (
          <span
            className="text-link"
            data-value={value}
            onClick={() => onEditEntry(rowData.row)}
          >
            {value}
          </span>
        );
      },
    });
    detailColumns.push({
      accessor: "endTime",
      header: "End Time",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      type: "string",
      Cell: (rowData) => {
        const endTime = rowData.row.endTime;
        const startTime = rowData.row.startTime;
        const value = moment(endTime).isValid()
          ? sharedHelper.formatDateTime(endTime, "h:mm a")
          : "-";
        const isNextDay = moment(endTime).isAfter(moment(startTime), "date");
        const isLink = value !== "-";
        return (
          <span
            className={`${
              isLink ? "text-link" : ""
            } d-flex align-items-start justify-content-center`}
            data-value={value}
            onClick={() => (isLink ? onEditEntry(rowData.row) : null)}
          >
            {value}
            {isNextDay ? (
              <small className="text-muted ms-1" style={{ fontSize: 10 }}>
                +1
              </small>
            ) : (
              ""
            )}
          </span>
        );
      },
    });
  }

  detailColumns.push({
    accessor: "hours",
    header: "Hours",
    headerProps: { className: "text-center" },
    cellProps: { className: "text-center" },
    type: "number",
    Cell: (rowData) => {
      const isInProgress =
        startEndTimeSettingEnabled &&
        rowData.row.startTime &&
        !rowData.row.endTime;
      const value = isInProgress
        ? "In Progress"
        : calculateHoursWithExceptions(rowData.row);
      const formattedValue = isNaN(value) ? value : value.toFixed(2);
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          data-value={formattedValue}
        >
          <span
            onClick={() => onEditEntry(rowData.row)}
            className="text-link"
            style={{ minWidth: 50 }}
          >
            {formattedValue}
          </span>
        </div>
      );
    },
  });

  detailColumns.push({
    accessor: "id",
    header: "Info",
    disableFilter: true,
    headerProps: { className: "text-end", style: { width: 150 } },
    cellProps: { className: "text-end", style: { width: 150 } },
    type: "number",
    Cell: (rowData) => {
      return (
        <div className="d-flex align-items-center justify-content-end">
          {rowData.row.exceptions.length ? (
            <TooltipItem
              id="exceptions-tooltip"
              title="Has Exceptions"
              cursor="default"
            >
              <Icon
                name="info"
                className="ms-3 text-primary"
                data-testid="info-circle-icon-auto"
              />
            </TooltipItem>
          ) : (
            "-"
          )}
        </div>
      );
    },
  });

  return editWorkTimeModal ? (
    <EditWorkTimeModal
      workTimes={[editWorkTimeModal]}
      employee={editWorkTimeModal.employee}
      onClose={() => {
        setEditWorkTimeModal();
      }}
      onSubmit={() => {
        onSubmitTimes();
        setEditWorkTimeModal();
      }}
    />
  ) : (
    <Modal isOpen={true} onClosed={handleClose} size="lg">
      <ModalHeader
        toggle={handleClose}
        className="d-flex justify-content-between"
      >
        {firstName && lastName ? `${firstName} ${lastName}` : "Time Detail"}
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loader size="sm" />
        ) : (
          <div>
            <AdvanceTable
              exportable
              exportName="LaborCostDateTotals.csv"
              columns={totalColumns}
              data={[
                {
                  totalHours,
                  workOrderNumber,
                  workTimeTypeName,
                },
              ]}
              pageSize={1}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "mb-0 table-layout-fixed",
              }}
            />
            <AdvanceTable
              className="mt-2"
              exportable
              exportName="LaborCostDetails.csv"
              columns={detailColumns}
              data={workTimes}
              pageSize={Math.max(workTimes.length, 1)}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "table-layout-fixed",
              }}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-end" data-testid="modal-footer">
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LaborCostDetail;
