import React, { useState, useCallback } from "react";
import { Col, Row, Button } from "reactstrap";
import InputEditable from "../InputEditable";
import { useFormGroups } from "../../providers/FormTemplateProvider";
import FormTemplateQuestions from "./FormTemplateQuestions";
import Icon from "../Icon";

const FormTemplateGroupItem = ({ group, isReadOnly }) => {
  const { updateGroupName, getErrors, getQuestions, copyGroup, deleteGroup } =
    useFormGroups();
  const [isExpanded, setIsExpanded] = useState(true);

  const questions = getQuestions(group.id);

  const handleUpdateName = useCallback(
    ($evt) => {
      const value = $evt.target.value;
      updateGroupName(group.id, value);
    },
    [updateGroupName, group.id]
  );

  const handleCopyGroup = useCallback(
    ($event) => {
      $event.stopPropagation();
      copyGroup(group);
    },
    [copyGroup, group]
  );

  const handleRemoveGroup = useCallback(
    ($event) => {
      $event.stopPropagation();
      deleteGroup(group.id);
    },
    [deleteGroup, group.id]
  );

  return (
    <div className="w-100 border border-1 border-opacity-25 solid rounded-4 py-3 px-4 bg-white sortable-item">
      <Row
        className="d-flex justify-content-between align-items-center gap-4"
        onClick={() => setIsExpanded((status) => !status)}
      >
        <Col>
          {isReadOnly ? (
            <span className="font-size-1-2">{group.name}</span>
          ) : (
            <InputEditable
              disabled={isReadOnly}
              onBlur={handleUpdateName}
              onClick={($evt) => $evt.stopPropagation()}
              defaultValue={group.name}
              placeholder="Enter a group name"
              name="group-name"
              size="medium"
              aria-label="Group Name"
              errors={getErrors(group.id)}
            />
          )}
        </Col>
        <Col className="d-flex justify-content-end align-items-center gap-3">
          <div className="small text-muted">
            {questions?.length || 0} questions
          </div>
          {!isReadOnly && (
            <>
              <Button
                size="sm"
                className="rounded-circle d-flex custom-rounded-button border-0"
                color="white"
                onClick={handleCopyGroup}
                aria-label="Copy Group"
              >
                <Icon name="copy" />
              </Button>
              <Button
                size="sm"
                className="rounded-circle d-flex custom-rounded-button text-danger border-0"
                color="white"
                onClick={handleRemoveGroup}
                aria-label="Remove Group"
              >
                <Icon name="trash" />
              </Button>
            </>
          )}
          <Button className="btn btn-link d-flex align-items-center gap-2 expand-button text-decoration-none">
            <small className="text-primary fw-bold">
              View {isExpanded ? "less" : "more"}
            </small>
            <Icon
              name="chevron-right"
              className={`mx-2 rotate ${isExpanded ? "rotate-90" : ""}`}
            />
          </Button>
        </Col>
      </Row>
      {isExpanded && (
        <Row className="group-item-content__content mt-3">
          <Col>
            <FormTemplateQuestions
              groupId={group.id}
              questions={questions}
              isReadOnly={isReadOnly}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default FormTemplateGroupItem;
