import React, { useEffect, useMemo } from "react";

import { useUpdateSidebarStatus } from "../../api/Sidebar.hooks";
import brand from "../../assets/img/logoPrimary.png";
import useElementWidth from "../../hooks/useElementWidth";
import useScrollPosition from "../../hooks/useScrollPosition";
import { useAuth } from "../../providers/authProvider";
import {
  ACTIONS as SIDEBAR_ACTIONS,
  useSidebar,
} from "../../providers/sidebarProvider";
import Icon from "../Icon";
import useElementHeight from "./../../hooks/useElementHeight";

const SidebarBrand = () => {
  const [authContext] = useAuth();
  const [sidebarContext, setSidebarContext] = useSidebar();

  const height = useElementHeight("section-header");
  const scroll = useScrollPosition();

  const top = useMemo(() => height - scroll - 10, [height, scroll]);

  const width = useElementWidth("ps-sidebar-container");

  const left = useMemo(() => width - 10, [width]);

  const { mutate: updateSidebarStatus } = useUpdateSidebarStatus();

  const onToggle = () => {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.TOGGLE,
    });
  };

  useEffect(() => {
    if (authContext.userData && sidebarContext.isOpen !== undefined) {
      updateSidebarStatus(
        {
          sidebarIsOpen: sidebarContext.isOpen,
        },
        { pathParams: { id: authContext.userData.id } }
      );
    }
  }, [authContext.userData, sidebarContext.isOpen, updateSidebarStatus]);

  useEffect(() => {
    if (authContext.userData && sidebarContext.isOpen === undefined) {
      setSidebarContext({
        action: SIDEBAR_ACTIONS.INIT_STATUS,
        payload: { sidebarIsOpen: authContext.userData.sidebarIsOpen },
      });
    }
  }, [authContext.userData, setSidebarContext, sidebarContext.isOpen]);

  return (
    <div
      className={`ps-sidebar-brand d-flex flex-column align-items-center justify-content-center gap-3 ${
        sidebarContext.isOpen ? "pt-4" : "pb-2 pt-4"
      } px-3`}
    >
      <img
        src={authContext.userData?.logo || brand}
        alt={process.env.REACT_APP_PROJECT_NAME}
        className="object-fit-cover"
      />
      {sidebarContext.isOpen ? (
        <span className="fs-4 text-black mb-0">
          {process.env.REACT_APP_PROJECT_NAME}
        </span>
      ) : null}
      <div
        className={`sidebar-toggle rotate ${
          !sidebarContext.isOpen ? "rotate-180" : ""
        }`}
        onClick={onToggle}
        style={{ top, left }}
      >
        <Icon name="chevron-left" />
      </div>
    </div>
  );
};

export default SidebarBrand;
