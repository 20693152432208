import React, { useEffect, useMemo, useState } from "react";

import { Row, Button, Table } from "reactstrap";

import { components, sharedHelper, data } from "@crewos/shared";

import { useDeleteCrew } from "../api/Crews.hooks";
import { ACTIONS, useCrews } from "../providers/crewsProvider";
import { crewHelper } from "../helpers/crewHelper";
import WorkOrderCrewIndicators from "./WorkOrderCrewIndicators";
import CrewModal from "./CrewModal";
import CrewWorkDayCrew from "./CrewWorkDayCrew";

const { Loader, ConfirmationModal, InformationModal } = components;

const { useAuth } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CREWS_PACKAGE = "crews";

const Crew = ({ crew }) => {
  const [authContext] = useAuth();
  const [, setCrewsContext] = useCrews();

  const [loading, setLoading] = useState();

  const [crewModal, setCrewModal] = useState();

  const [informationModal, setInformationModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const { data: deleteCrewData, deleteCrew } = useDeleteCrew();

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  useEffect(() => {
    if (deleteCrewData) {
      setLoading();
      sharedHelper.successToast(
        `${directAssignationEnabled ? "Assignment" : "Crew"} deleted.`
      );
      setCrewsContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [deleteCrewData, setCrewsContext, directAssignationEnabled]);

  const onDeleteCrew = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await deleteCrew(crew.id);
        setConfirmationModal();
        setLoading(true);
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: `Delete ${directAssignationEnabled ? "Assignment" : "Crew"}`,
      body: `<span class="text-center">Do you confirm you want to delete this ${
        directAssignationEnabled ? "assignment" : "crew"
      } including all it's associated data?</span>`,
      confirmText: "Delete",
    });
  };

  const onCrewHistory = (crew) => {
    return setInformationModal({
      title: "Crew History",
      rawBody: true,
      size: "xl",
      onClose: () => setInformationModal(),
      body: (
        <Table
          className="col-12 px-0 mb-0 overflow-hidden"
          striped
          size="small"
        >
          <thead>
            <tr className="bg-graylight small">
              <th>Name</th>
              <th>Role</th>
              <th>Crew Lead</th>
              <th>From</th>
              <th>To</th>
            </tr>
          </thead>
          <tbody className="small">
            {crew.employeeCrews
              .sort(sharedHelper.sortEmployeeCrew)
              .map((employeeCrew) => (
                <tr key={employeeCrew.id}>
                  <td>{`${employeeCrew.employee?.firstName} ${employeeCrew.employee?.lastName}`}</td>
                  <td>{employeeCrew.role.name}</td>
                  <td>{employeeCrew.isLead ? "Yes" : "No"}</td>
                  <td>{sharedHelper.formatDateTime(employeeCrew.createdAt)}</td>
                  <td>
                    {employeeCrew.disabledAt
                      ? sharedHelper.formatDateTime(employeeCrew.disabledAt)
                      : "Present"}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ),
    });
  };

  const actions = loading ? (
    <Loader size="sm" align="end" color="light" />
  ) : !directAssignationEnabled ? (
    <div className="d-flex justify-content-end align-items-center">
      <Button
        color="none"
        className="ms-2 text-primary px-2 d-flex align-items-center"
        size="sm"
        onClick={(evt) => {
          evt.stopPropagation();
          setCrewModal(true);
        }}
      >
        Edit
      </Button>
      <Button
        color="none"
        className="ms-2 text-primary px-2 d-flex align-items-center"
        size="sm"
        onClick={(evt) => {
          evt.stopPropagation();
          onCrewHistory(crew);
        }}
      >
        History
      </Button>
      <Button
        color="none"
        className="ms-2 text-danger px-2 d-flex align-items-center"
        size="sm"
        onClick={(evt) => {
          evt.stopPropagation();
          onDeleteCrew();
        }}
      >
        Delete
      </Button>
    </div>
  ) : null;

  const EmployeeCrew = ({ employeeCrew, className = "" }) => (
    <div className={`col-6 col-md-3 px-2 ${className}`}>
      <div
        className="bg-white d-flex align-items-center justify-content-between px-3 py-2 border-radius-default"
        key={employeeCrew.id}
      >
        <div className="d-flex flex-column">
          <span>{sharedHelper.getName(employeeCrew.employee)}</span>
          <small className="text-muted">{employeeCrew.role.name}</small>
        </div>
        <WorkOrderCrewIndicators
          entry={employeeCrew}
          onDeleteCrew={onDeleteCrew}
        />
      </div>
    </div>
  );

  const activeCrew = useMemo(
    () => crewHelper.filterCrew(crew, employeeCrewPastDatesEnabled),
    [crew, employeeCrewPastDatesEnabled]
  );

  const crewLead = useMemo(
    () => activeCrew.find((ec) => ec.isLead),
    [activeCrew]
  );

  return (
    <>
      {directAssignationEnabled ? (
        <EmployeeCrew employeeCrew={crewLead} className="mb-2" />
      ) : (
        <CrewWorkDayCrew crew={crew} actions={actions}>
          {crew.employeeCrews.length ? (
            <Row className="mt-n2 mb-2 d-flex flex-grow-1 flex-wrap">
              {activeCrew.map((employeeCrew) => (
                <EmployeeCrew
                  className="my-1"
                  employeeCrew={employeeCrew}
                  key={employeeCrew.id}
                />
              ))}
            </Row>
          ) : (
            <div className="mb-2 text-center text-muted">Empty Crew</div>
          )}
        </CrewWorkDayCrew>
      )}
      {informationModal ? (
        <InformationModal {...informationModal} />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : crewModal ? (
        <CrewModal
          defaultCrew={crew}
          onClose={() => setCrewModal(false)}
          onSubmit={() => {
            setCrewModal(false);
            setCrewsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </>
  );
};

export default Crew;
