import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  ListGroupItem,
  ListGroup,
} from "reactstrap";

import { useCreateUser, useUpdateUser, useGetUsers } from "../api/Users.hooks";
import { useGetEmployees } from "@crewos/employees";
import { useGetAllUserRoles } from "../api/UserRoles.hooks";

import { components, sharedHelper, data } from "@crewos/shared";

const { useAuth } = data;

const {
  Select,
  Loader,
  ConfirmationModal,
  InformationModal,
  TooltipItem,
  Icon,
} = components;

const MAX_PAGE_SIZE = Number.MAX_SAFE_INTEGER;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const UserModal = ({ id, onClose, onSubmit }) => {
  const [authContext] = useAuth();

  const [userData, setUserData] = useState({
    isActive: true,
    isInternal: false,
    serviceLocations: [],
  });
  const [confirmationModal, setConfirmationModal] = useState();
  const [informationModal, setInformationModal] = useState();
  const [userServiceLocations, setUserServiceLocations] = useState([]);

  const {
    data: getUserData,
    isLoading: isLoadingGetUsers,
    get: getUsers,
  } = useGetUsers();

  useEffect(() => {
    if (id) {
      getUsers({ id });
    }
  }, [getUsers, id]);

  useEffect(() => {
    if (getUserData) {
      setUserData(getUserData);
    }
  }, [getUserData, setUserData]);

  useEffect(() => {
    setUserServiceLocations(
      (userData.serviceLocations || []).map((sl) => ({
        value: sl.id,
        label: sl.name,
      }))
    );
  }, [userData.serviceLocations, setUserServiceLocations]);

  const {
    isLoading: isLoadingUpdateUser,
    update: updateUser,
    data: updateUserData,
  } = useUpdateUser();

  const {
    isLoading: isLoadingCreateUser,
    mutate: createUser,
    data: createUserData,
  } = useCreateUser();

  const {
    data: employees,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    get: getUserRoles,
  } = useGetAllUserRoles();

  useEffect(() => {
    if (updateUserData) {
      sharedHelper.successToast("User saved");
      onSubmit();
    }
  }, [updateUserData, onSubmit]);

  useEffect(() => {
    if (createUserData) {
      sharedHelper.successToast(`User created`);
      onSubmit();
    }
  }, [createUserData, onSubmit]);

  useEffect(() => {
    getEmployees({
      pageSize: MAX_PAGE_SIZE,
    });
  }, [getEmployees]);

  useEffect(() => {
    getUserRoles();
  }, [getUserRoles]);

  const serviceLocationEnabled = useMemo(
    () =>
      authContext.userData
        ? sharedHelper.isSettingEnabled(
            authContext.userData.packages,
            "core",
            "SERVICE_LOCATIONS_ENABLED"
          )
        : false,
    [authContext.userData]
  );

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!hideServiceLocations && !userServiceLocations.length) {
      return sharedHelper.warningToast(
        "Please select at least one service location."
      );
    }
    const serviceLocations = userServiceLocations.map((sl) => sl.value);
    if (id) {
      if (id === authContext.userData.id && !userData.isActive) {
        return sharedHelper.errorToast("You can't deactivate yourself");
      }
      await updateUser({
        ...userData,
        serviceLocations,
      });
    } else {
      await createUser({
        ...userData,
        serviceLocations,
      });
    }
  };

  const onClearTokens = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await updateUser({ ...userData, clearTokens: true });
        setConfirmationModal();
        sharedHelper.successToast("User session terminated");
      },
      onClose: () => {
        setConfirmationModal();
      },
      rawBody: true,
      title: `Terminate user session`,
      body: `<span>The user session will be terminated and will need to relogin.</span><p class="mb-0 mt-2">Are you sure you want to continue?</p>`,
      confirmText: "Terminate",
    });
  };

  const onReset2FA = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await updateUser({ ...userData, reset2FA: true });
        setConfirmationModal();
        sharedHelper.successToast("User 2FA reset");
      },
      onClose: () => {
        setConfirmationModal();
      },
      rawBody: true,
      title: `Reset user 2FA`,
      body: `<span>The user 2FA will be reset and will need to set it up again.</span><p class="mb-0 mt-2">Are you sure you want to continue?</p>`,
      confirmText: "Reset",
    });
  };

  const onSendResetDB = (action) => {
    const isReset = action === "appResetDB";
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await updateUser({ ...userData, [action]: true });
        setConfirmationModal();
        sharedHelper.successToast(`User DB was ${isReset ? "reset" : "sent"}`);
      },
      onClose: () => {
        setConfirmationModal();
      },
      rawBody: true,
      title: `${isReset ? "Reset" : "Send"} User DB`,
      body: `<span>The user DB will be sent by email${
        isReset ? " and then reset" : ""
      }.</span><p class="mb-0 mt-2">Are you sure you want to continue?</p>`,
      confirmText: `${isReset ? "Reset" : "Send"}`,
    });
  };

  const isSuperAdmin = useMemo(
    () =>
      sharedHelper.userHasScope(authContext.userData?.role, [
        IS_SUPER_ADMIN_USER,
      ]),
    [authContext.userData]
  );

  const appUserRoles = useMemo(() => {
    if (!userRoles) {
      return [];
    }
    return (
      userRoles
        .filter((role) => sharedHelper.isRoleAppUser(role))
        .map((role) => role.id) || []
    );
  }, [userRoles]);

  const onPendingSyncItems = () => {
    return setInformationModal({
      title: "Pending Sync Items",
      rawBody: true,
      size: "lg",
      onClose: () => setInformationModal(),
      body: userData.pendingSyncItems.length ? (
        userData.pendingSyncItems.map((item) => (
          <ListGroup className="small">
            <ListGroupItem className="my-2 d-flex justify-content-center align-items-center">
              <code>{JSON.stringify(item)}</code>
            </ListGroupItem>
          </ListGroup>
        ))
      ) : (
        <small className="d-flex justify-content-center align-items-center">
          No pending sync items
        </small>
      ),
    });
  };

  const isLoading = useMemo(
    () => isLoadingGetUsers || isLoadingCreateUser || isLoadingUpdateUser,
    [isLoadingGetUsers, isLoadingCreateUser, isLoadingUpdateUser]
  );

  const roleSelect = useMemo(() => {
    let roles = [];

    userRoles?.forEach((role) => {
      roles.push({ value: role.id, label: role.name });
    });

    if (userData.role && !userData.role.isActive) {
      roles.push({ value: userData.userRoleId, label: userData.role.name });
    }

    return roles;
  }, [userRoles, userData.role, userData.userRoleId]);

  const defaultRole = useMemo(() => {
    return roleSelect.find((role) => role.value === userData.userRoleId);
  }, [roleSelect, userData.userRoleId]);

  const employeeSelect = useMemo(() => {
    let optiones = [];

    employees?.data?.forEach((employee) => {
      optiones.push({
        value: employee.id,
        label: `${employee.firstName} ${employee.lastName}`,
      });
    });

    if (userData.employee && !userData.employee.isActive) {
      optiones.push({
        value: userData.employeeId,
        label: `${userData.employee.firstName} ${userData.employee.lastName}`,
      });
    }

    return optiones;
  }, [employees, userData.employee, userData.employeeId]);

  const defaultEmployee = useMemo(() => {
    return (
      employeeSelect.find(
        (employee) => employee.value === userData.employeeId
      ) || ""
    );
  }, [employeeSelect, userData.employeeId]);

  const statusSelect = useMemo(() => {
    return [
      { value: true, label: "Active" },
      { value: false, label: "Inactive" },
    ];
  }, []);

  const defaultStatus = useMemo(() => {
    return statusSelect.find((status) => status.value === userData.isActive);
  }, [userData.isActive, statusSelect]);

  const internalSelect = useMemo(() => {
    return [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ];
  }, []);

  const defaultInternal = useMemo(() => {
    return internalSelect.find(
      (internal) => internal.value === userData.isInternal
    );
  }, [userData.isInternal, internalSelect]);

  const serviceLocationsOptions = useMemo(() => {
    if (authContext.userData) {
      return authContext.userData.serviceLocations
        .filter(
          (sl) => !userServiceLocations.find((usl) => usl.value === sl.id)
        )
        .map((sl) => ({ value: sl.id, label: sl.name }));
    }
  }, [authContext.userData, userServiceLocations]);

  const hideServiceLocations = useMemo(() => {
    if (!serviceLocationEnabled || !userRoles || !userData.userRoleId) {
      return true;
    }
    const userRole = userRoles.find((role) => role.id === userData.userRoleId);
    if (!userRole) {
      return true;
    }
    return userRole.userRoleScopes.find(
      (scope) => scope.name === "IS_ADMIN_USER"
    );
  }, [userRoles, userData.userRoleId, serviceLocationEnabled]);

  const isAppUserRole = useMemo(
    () => appUserRoles.includes(userData.userRoleId),
    [appUserRoles, userData.userRoleId]
  );

  return informationModal ? (
    <InformationModal {...informationModal} />
  ) : confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {id ? "Edit" : "Create"} User
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoading ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Label className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <small>First Name</small>
                      <small className="ms-1 text-danger">*</small>
                    </div>
                    {userData.employeeId ? (
                      <TooltipItem
                        className="ms-2"
                        id="fname-tooltip"
                        title="Locked to selected employee first name"
                      >
                        <Icon
                          name="info"
                          data-testid="info-icon"
                          className="text-primary"
                          style={{ paddingBottom: "1px" }}
                        />
                      </TooltipItem>
                    ) : null}
                  </Label>
                  <input
                    className="form-control-redesign"
                    disabled={userData.employeeId ? true : false}
                    maxLength="50"
                    type="text"
                    name="name"
                    value={userData.firstName || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        firstName: e.target.value,
                      })
                    }
                    required
                    placeholder="Enter a first name"
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <small>Last Name</small>
                      <small className="ms-1 text-danger">*</small>
                    </div>
                    {userData.employeeId ? (
                      <TooltipItem
                        className="ms-2"
                        id="fname-tooltip"
                        title="Locked to selected employee last name"
                      >
                        <Icon
                          name="info"
                          data-testid="info-icon"
                          className="text-primary"
                          style={{ paddingBottom: "1px" }}
                        />
                      </TooltipItem>
                    ) : null}
                  </Label>
                  <input
                    className="form-control-redesign"
                    disabled={userData.employeeId ? true : false}
                    maxLength="50"
                    type="text"
                    name="name"
                    value={userData.lastName || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        lastName: e.target.value,
                      })
                    }
                    required
                    placeholder="Enter a last name"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Email</span>
                    <span className="text-danger ms-2">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    maxLength="50"
                    type="email"
                    name="email"
                    value={userData.email || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        email: e.target.value.trim(),
                      })
                    }
                    required
                    placeholder="Enter an email"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Password</span>
                    {!id ? <span className="text-danger ms-2">*</span> : null}
                  </Label>
                  <input
                    className="form-control-redesign"
                    required={!id}
                    autoComplete="new-password"
                    maxLength="50"
                    type="password"
                    name="password"
                    value={userData.password || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        password: e.target.value.trim(),
                      })
                    }
                    placeholder="Enter a password"
                  />
                </FormGroup>
                {isAppUserRole ? (
                  <FormGroup>
                    <Label>
                      <span>NFC Token</span>
                    </Label>
                    <input
                      className="form-control-redesign"
                      maxLength="50"
                      type="text"
                      name="NFCToken"
                      value={userData.NFCToken || ""}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          NFCToken: e.target.value,
                        })
                      }
                      placeholder="Enter a NFC token"
                    />
                  </FormGroup>
                ) : null}
                {isLoadingUserRoles ? (
                  <Loader size="sm" />
                ) : (
                  <FormGroup>
                    <Label>
                      <span>Role</span>
                      <span className="text-danger ms-2">*</span>
                    </Label>
                    <Select
                      name="roleSelect"
                      onChange={(selected) =>
                        setUserData({
                          ...userData,
                          userRoleId: selected.value,
                          employeeId: null,
                          NFCToken: null,
                        })
                      }
                      value={defaultRole}
                      placeholder="Select a role"
                      options={roleSelect}
                      data-testid="role-select"
                      required
                    />
                  </FormGroup>
                )}
                {isLoadingEmployees || isLoadingUserRoles ? (
                  <Loader size="sm" />
                ) : isAppUserRole ? (
                  <FormGroup>
                    <Label>
                      <span>Employee</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <Select
                      name="employeeSelect"
                      data-testid="employee-select"
                      onChange={(selected) => {
                        const employee = employees.data.find(
                          (s) => s.id === selected.value
                        );
                        if (employee) {
                          setUserData({
                            ...userData,
                            employeeId: employee.id,
                            firstName: employee.firstName,
                            lastName: employee.lastName,
                            serviceLocations: [...employee.serviceLocations],
                          });
                        }
                      }}
                      value={defaultEmployee}
                      placeholder="Select the employee"
                      options={employeeSelect}
                    />
                  </FormGroup>
                ) : null}
                {id ? (
                  <FormGroup className="mb-0">
                    <Label>
                      <span>Status</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <Select
                      id="statusSelect"
                      name="statusSelect"
                      onChange={(selected) => {
                        setUserData({
                          ...userData,
                          isActive: selected.value,
                        });
                      }}
                      value={defaultStatus}
                      options={statusSelect}
                      required
                    />
                  </FormGroup>
                ) : null}
                {isSuperAdmin ? (
                  <FormGroup className="mb-0">
                    <Label>
                      <span>Is Internal</span>
                    </Label>
                    <Select
                      id="internalSelect"
                      name="internalSelect"
                      onChange={(selected) => {
                        setUserData({
                          ...userData,
                          isInternal: selected.value,
                        });
                      }}
                      value={defaultInternal}
                      options={internalSelect}
                      required
                    />
                  </FormGroup>
                ) : null}
                {hideServiceLocations ? null : (
                  <FormGroup>
                    <Label className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <small>Service Locations</small>
                        <small className="ms-1 text-danger">*</small>
                      </div>
                      {userData.employeeId ? (
                        <TooltipItem
                          className="ms-2"
                          id="fname-tooltip"
                          title="Locked to selected employee locations"
                        >
                          <Icon
                            name="info"
                            data-testid="info-icon"
                            className="text-primary"
                            style={{ paddingBottom: "1px" }}
                          />
                        </TooltipItem>
                      ) : null}
                    </Label>
                    <Select
                      isDisabled={userData.employeeId ? true : false}
                      isMulti={true}
                      name="slSelect"
                      onChange={setUserServiceLocations}
                      value={userServiceLocations}
                      placeholder="Select service locations"
                      options={serviceLocationsOptions}
                      data-testid="service-locations-select"
                      required
                    />
                  </FormGroup>
                )}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex flex-column justify-content-center">
          {id && isSuperAdmin ? (
            <div className="d-flex align-items-center justify-content-start mt-n2 w-100 gap-1">
              <Button
                size="sm"
                color="secondary"
                className="min-width-auto d-none"
                onClick={() => onSendResetDB("appSendDB")}
              >
                Send APP DB
              </Button>
              <Button
                size="sm"
                color="secondary"
                className="min-width-auto d-none"
                onClick={() => onSendResetDB("appResetDB")}
              >
                Reset APP DB
              </Button>
              <Button
                size="sm"
                color="light"
                className="min-width-auto"
                onClick={onPendingSyncItems}
              >
                See Sync Items
              </Button>
              <Button
                className="min-width-auto"
                size="sm"
                color="light"
                onClick={onClearTokens}
              >
                Kill Session
              </Button>
              <Button
                className="min-width-auto"
                size="sm"
                color="light"
                onClick={onReset2FA}
              >
                Reset 2FA
              </Button>
            </div>
          ) : null}
          <div className="d-flex align-items-center justify-content-between w-100">
            <Button color="secondary" onClick={onClose} className="text-dark">
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserModal;
