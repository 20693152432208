import React, { useEffect, useState } from "react";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components } from "@crewos/shared";

import {
  useDeleteNonJobTimeType,
  useGetNonJobTimeTypes,
} from "../../api/NonJobTimeTypes.hooks";
import NonJobTimeTypeModal from "../../components/NonJobTimeTypeModal";

const { Icon, AdvanceTable, AdvanceTablePagination, ConfirmationModal } =
  components;

const columns = (onDelete, onEdit) => [
  {
    accessor: "name",
    header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.name;
    },
  },
  {
    accessor: "shortName",
    header: "Short Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.shortName;
    },
  },
  {
    accessor: "billingType",
    header: "Billing Type",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    type: "number",
    Cell: (rowData) => {
      return rowData.row.billingType;
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    cellProps: {
      className: "text-end",
    },
    headerProps: {
      className: "text-truncate text-end",
      style: { width: 300 },
    },
    Cell: (rowData) => (
      <>
        <Button
          size="sm"
          color="none"
          className="ms-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          className="ms-2 text-danger"
          color="none"
          onClick={() => onDelete(rowData.row)}
        >
          Delete
        </Button>
      </>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const NonJobTimeTypes = () => {
  const [NonJobTimeTypes, setNonJobTimeTypes] = useState({});
  const [refresh, setRefresh] = useState();
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [createModal, setCreateModal] = useState();
  const [updateModal, setUpdateModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: getNonJobTimeTypesData,
    isLoading: isLoadingGetNonJobTimeTypes,
    get: getNonJobTimeTypes,
  } = useGetNonJobTimeTypes();

  useEffect(() => {
    getNonJobTimeTypes({
      page,
      pageSize: sizePerPage,
    });
  }, [getNonJobTimeTypes, refresh, page, sizePerPage]);

  useEffect(() => {
    if (getNonJobTimeTypesData) {
      setNonJobTimeTypes(getNonJobTimeTypesData);
    }
  }, [getNonJobTimeTypesData, setNonJobTimeTypes]);

  const { data: deleteNonJobTimeTypeData, deleteNonJobTimeType } =
    useDeleteNonJobTimeType();

  useEffect(() => {
    if (deleteNonJobTimeTypeData) {
      setRefresh((prev) => !prev);
    }
  }, [deleteNonJobTimeTypeData]);

  const onEdit = (NonJobTimeType) => {
    setUpdateModal(NonJobTimeType);
  };

  const onDelete = (NonJobTimeType) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteNonJobTimeType(NonJobTimeType.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Non Job Time Type",
      body: `Are you sure you want to delete ${NonJobTimeType.name}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Non Job Time Types</h2>
            <small className="text-muted ms-2 pt-1">
              ({NonJobTimeTypes.count})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onDelete, onEdit)}
              data={NonJobTimeTypes.data || []}
              pageSize={sizePerPage}
              isLoading={isLoadingGetNonJobTimeTypes}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={NonJobTimeTypes.count}
            pageCount={NonJobTimeTypes.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : createModal ? (
        <NonJobTimeTypeModal
          onClose={() => setCreateModal()}
          onSubmit={() => {
            setCreateModal();
            setRefresh((prev) => !prev);
          }}
        />
      ) : updateModal ? (
        <NonJobTimeTypeModal
          nonJobTimeType={updateModal}
          onClose={() => setUpdateModal()}
          onSubmit={() => {
            setUpdateModal();
            setRefresh((prev) => !prev);
          }}
        />
      ) : null}
    </Container>
  );
};

export default NonJobTimeTypes;
