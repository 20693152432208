import React, { useCallback, useState } from "react";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const DeleteModal = ({ isOpen, name, onClose, onDelete, title, isLoading }) => {
  const [invalidInput, setInvalidInput] = useState(false);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      const value = formData.get("confirm-delete").trim().toUpperCase();
      if (value === "DELETE") {
        event.target.reset();
        onDelete();
      } else {
        setInvalidInput(true);
      }
    },
    [onDelete]
  );

  return (
    <Modal isOpen={isOpen} size="sm">
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={onClose} className="border-bottom-0 text-lg">
          {title || `Delete ${name}`}
        </ModalHeader>
        <ModalBody>
          <p className="text-danger text-sm">
            This action can not be reversed:
          </p>
          <p className="fw-bold">{name}</p>
          <div>
            <FormGroup>
              <Label for="confirm-delete">
                Type the word <strong>DELETE</strong> to confirm{" "}
                <span className="text-danger">*</span>
              </Label>
              <input
                id="confirm-delete"
                name="confirm-delete"
                maxLength="6"
                type="text"
                placeholder="Enter DELETE"
                className="form-control-redesign"
                required
                invalid={invalidInput}
              />
              <FormFeedback>Please type DELETE to confirm.</FormFeedback>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button color="danger" type="submit" disabled={isLoading}>
            Delete
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default DeleteModal;
